import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useSelector } from "react-redux";
import { LoadingBtn } from "../../toastify";

const Pagination = ({ Page, setPage }) => {
  const { pageData, status } = useSelector((state) => state.filterShoes);
  const [currentPage, setCurrentPage] = useState(Page);

  useEffect(() => {
    setCurrentPage(Page);
  }, [Page]);

  // Function to generate "Xoolen" style pagination
  const renderXolenPagination = () => {
    if (status !== "idle" || !pageData) return null;

    const totalPages = pageData.length;
    const oPages = pageData.slice(1, totalPages); // Extract middle pages for looping 'o's

    return (
      <>
        <button
          onClick={() => setPage(currentPage)}
          className={`text-lg font-extrabold`}
        >
          X 
        </button>
        {oPages.map((page) => (
          <button
            key={page}
            onClick={() => setPage(page)}
            className={`px-2 py-1 text-lg font-bold ${
              currentPage === page ? "font-extrabold text-gray-600" : ""
            }`}
          >
            o {/* Loop 'o's for middle pages */}
          </button>
        ))}
        <button
          onClick={() => setPage(currentPage)}
          className={`text-lg font-extrabold`}
        >
          len
        </button>
      </>
    );
  };

  return (
    <div className="flex shadow-none lg:shadow-none md:shadow-none sm:shadow-none items-center justify-center mt-4 w-full max-w-[600px] mx-auto space-x-2">
      {/* Previous Page Button */}
      <button
        onClick={() => {
          if (currentPage > 1) {
            setPage(currentPage - 1);
            setCurrentPage(currentPage - 1);
          }
        }}
        className="bg-white hover:bg-gray-200 transition text-black"
      >
        <BiChevronLeft />
      </button>

      {/* Xoolen in the center */}
      <div className="flex space-x-0">
        {status === "loading" ? (
          <LoadingBtn color={"black"} width={10} />
        ) : (
          renderXolenPagination()
        )}
      </div>

      {/* Next Page Button */}
      <button
        onClick={() => {
          if (currentPage < pageData.length) {
            setPage(currentPage + 1);
            setCurrentPage(currentPage + 1);
          }
        }}
        className="bg-white hover:bg-gray-200 transition text-black"
      >
        <BiChevronRight />
      </button>
    </div>
  );
};

export default Pagination;
