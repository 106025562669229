import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../components/filterProduct/Search";
import ProductList from "../components/ProductList";
import { getAllShoe } from "../statemanagement/slice/ShoeSlice";
import Pagination from "../components/paginationButtons";
import Banner from "../components/Banner";

const Home = ({search = false}) => {
  const dispatch = useDispatch();

  const [Page, setPage] = useState(1);

  const { shoeData, loading, error } = useSelector(
    (state) => state.shoeDetails
  );
  const { limit, sort, brand, category, price, searchQuery } = useSelector(
    (state) => state.filterShoes
  );

  // Fetch products based on filters and current page
  React.useEffect(() => {
    dispatch(
      getAllShoe({
        page: Page,
        limit,
        sort,
        brand,
        category,
        price,
        searchQuery,
      })
    );
  }, [dispatch, Page, limit, sort, brand, category, price, searchQuery]);

  const style = {
    textAlign: "left",
    marginLeft: "10px",
  };

  return (
    <div className="min-h-[1400px]">
      {search &&
      <Search
        brandValue={brand}
        categoryValue={category}
        priceValue={price}
        pageValue={Page}
        loading={loading}
        searchOnly={true}
        show={true}
      />
      }
      {!search &&
      <Search
        brandValue={brand}
        categoryValue={category}
        priceValue={price}
        pageValue={Page}
        loading={loading}
        searchOnly={true}
        show={false}
      />
      }

      <Banner />

      {/* Product list */}
      <ProductList
        data={shoeData}
        loading={loading}
        error={error}
        category={category}
        title="Latest Collection"
        style={style}
        limit={8}
      />

      {/* Pagination Component */}
      <Pagination Page={Page} setPage={setPage} />
    </div>
  );
}

export default Home;
