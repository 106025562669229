import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../components/filterProduct/Search";
import ProductList from "../components/ProductList";
import { getAllShoe } from "../statemanagement/slice/ShoeSlice";
import Pagination from "./../components/paginationButtons";

function ProductPage({ search = true }) {
  const dispatch = useDispatch();

  const [Page, setPage] = useState(1);

  const { shoeData, loading, error } = useSelector(
    (state) => state.shoeDetails
  );
  const { limit, sort, brand, category, price, searchQuery } = useSelector(
    (state) => state.filterShoes
  );

  // Fetch products based on filters and current page
  React.useEffect(() => {
    dispatch(
      getAllShoe({
        page: Page,
        limit,
        sort,
        brand,
        category,
        price,
        searchQuery,
      })
    );
  }, [dispatch, Page, limit, sort, brand, category, price, searchQuery]);

  const style = {
    textAlign: "left",
    marginLeft: "10px",
  };

  return (
    <div className="min-h-[600px] mt-10">
      {search && (
        <Search
          brandValue={brand}
          categoryValue={category}
          priceValue={price}
          pageValue={Page}
          loading={loading}
          searchOnly={false}
          show={true}
        />
      )}
      {!search && (
        <Search
          brandValue={brand}
          categoryValue={category}
          priceValue={price}
          pageValue={Page}
          loading={loading}
          searchOnly={false}
          show={false}
        />
      )}

      {/* Product list */}
      <ProductList
        data={shoeData}
        loading={loading}
        error={error}
        category={category}
        title="All Products"
        style={style}
        limit={12}
      />

      {/* Pagination Component */}
      <Pagination Page={Page} setPage={setPage} />
    </div>
  );
}

export default ProductPage;
